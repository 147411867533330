<template>
	<div class="fater-body-show">
        <Row :gutter="0">
            <Col span="24">
                <Card>
                    <template #title style="background-color: aquamarine;"> 
                        <p style="color:cornflowerblue;">打卡数据显示</p>
                        <Icon type="md-flame"  color="red" size:100px/>
                        <Icon type="md-flame"  color="red" size:100px/>
                        <Icon type="md-flame"  color="red" size:100px/>
                        <Icon type="md-flame"  color="red" size:100px/>
                        <Icon type="md-flame"  color="red" size:100px/>
                    </template>
                    <div ref="lineSeatOrderRanking" style="width: 100%;height: 300px;"></div>
                </Card>
            </Col>
        </Row>

        <Row :gutter="16">
            <Col span="8">
                <Card>
                    <template #title>
                        会议
                    </template>
                    <div ref="barUseVsFree" style="width: 100%;height: 240px;"></div>
                </Card>
                <Card>
                    <template #title>
                        未打卡人员
                    </template>
                    <div ref="barNormalVsBlack" style="width: 100%;height: 240px;"></div>
                </Card>
            </Col>
          
            <Col span="16" >
                <Card>
                    <template #title>
                        打卡情况
                    </template>
                    <div ref="lineMakeSeat" style="width: 100%;height: 240px;"></div>
                </Card>
            
            </Col>

        </Row>   
       
    </div>
</template>

<style></style>

<script>
import { Icon } from 'view-ui-plus';
import {
    getDayStatisSeatUSeVSFree,
    getMonthStatisSeatUse,
    getMonthStatisSeatMake,
    getDayStatisStuNormalVSBlack,
    getSeatOrderRanking
} from '../../api/index.js';
import * as echarts from "echarts";
export default{
    data() {
        return {
            useSeatData: {
                items: [],
                vals: []
            },
            makeSeatData: {
                items: [],
                vals: []
            },
            seatOrderData: {
                items: [],
                vals: [],
                status:[],
                make_date:[],
                make_time:[]
            },
            daySeatUSeVSFreeData: [],
            dayStuNormalVSBlack: [],
        };
    },
    methods: {
        drawLineSeatMake() {
            let lineMakeSeat = echarts.init(this.$refs.lineMakeSeat);
            lineMakeSeat.setOption({
                tooltip: {
                    trigger: "axis"
                },
                xAxis: {
                    type: "category",
                    data: this.makeSeatData.items
                },
                yAxis: {
                    type: "value"
                },
                series: [
                    {
                        data: this.makeSeatData.vals,
                        type: "line",
                        smooth: true
                    }
                ]
            });
        },
        //画出预约座位排行图
        drawLineSeatOrderRanking() {
            const seatOrderData = this.seatOrderData;
            let lineSeatOrderRanking = echarts.init(this.$refs.lineSeatOrderRanking);
            lineSeatOrderRanking.setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer:{
                        type:"shadow"
                    },
                    formatter:function(params){
                        const dataIndex = params[0].dataIndex; 

                        //根据状态的不同返回不同的提示信息
                        if(seatOrderData.status[dataIndex]==0){
                            const iconHtml = '<i class="ivu-icon ivu-icon-ios-bookmark" style="color:#19be6b;"></i>';
                            return params[0].axisValue +   
                                "</br>预约次数:" + params[0].value  +
                               "</br>状态:"+  iconHtml + "无预约"
                        }else if(seatOrderData.status[dataIndex]==1){
                            const iconHtml = '<i class="ivu-icon ivu-icon-ios-bookmark" style="color:#ed4014;" ></i>';
                            return params[0].axisValue +   
                                "</br>预约次数:" + params[0].value  +
                                "</br>状态:"+ iconHtml+ 
                                "已被预约" + 
                                "</br>最近预约日期:" + seatOrderData.make_date[dataIndex] +
                                "</br>预约时间:" + seatOrderData.make_time[dataIndex] ;
                        }else if(seatOrderData.status[dataIndex]==2){
                            const iconHtml = '<i class="ivu-icon ivu-icon-ios-bookmark" style="color:#19be6b;" ></i>';
                            return params[0].axisValue +   
                                "</br>预约次数:" + params[0].value  +
                                "</br>状态:"+ iconHtml+ 
                                "使用中..."
                        }else{
                            const iconHtml = '<i class="ivu-icon ivu-icon-ios-bookmark" style="color:#2db7f5;" ></i>';
                            return params[0].axisValue +   
                                "</br>预约次数:" + params[0].value  +
                                "</br>状态:"+ iconHtml+ 
                                "使用结束，当前座位空闲中..." 
                        }
                       
                    },
                },
                xAxis: {
                    type: "category",
                    data: this.seatOrderData.items,
                    name:"预约座位"
                },
                yAxis: {
                    type: "value",
                    name:"预约次数"
                },
                series: [
                    {
                        data: this.seatOrderData.vals,
                        type: "bar",
                        showBackground: true,
                        backgroundStyle: {
                            color: "rgba(180, 180, 180, 0.2)"
                        }
                    }
                ]
            });
        },
        drawLineSeatMake() {
            let lineMakeSeat = echarts.init(this.$refs.lineMakeSeat);
            lineMakeSeat.setOption({
                tooltip: {
                    trigger: "axis"
                },
                xAxis: {
                    type: "category",
                    data: this.makeSeatData.items
                },
                yAxis: {
                    type: "value"
                },
                series: [
                    {
                        data: this.makeSeatData.vals,
                        type: "line",
                        smooth: true
                    }
                ]
            }
            
            );
        },
        drawLineSeatUse() {
            let lineUseSeat = echarts.init(this.$refs.lineUseSeat);
            lineUseSeat.setOption({
                xAxis: {
                    type: "category",
                    data: this.useSeatData.items
                },
                yAxis: {
                    type: "value"
                },
                series: [
                    {
                        data: this.useSeatData.vals,
                        type: "line",
                        smooth: true
                    }
                ]
            });
        },
        drawLineSeatMake() {
            let lineMakeSeat = echarts.init(this.$refs.lineMakeSeat);
            lineMakeSeat.setOption({
                xAxis: {
                    type: "category",
                    data: this.makeSeatData.items
                },
                yAxis: {
                    type: "value",
                    data: this.seatOrderData.vals
                },
                series: [
                    {
                        data: this.makeSeatData.vals,
                        type: "line",
                        smooth: true
                    }
                ]
            });
        },
        drawBarSeatUseVSFree() {
            let barUseVsFree = echarts.init(this.$refs.barUseVsFree);
            barUseVsFree.setOption({
                tooltip: {
                    trigger: "item"
                },
                legend: {
                    top: "5%",
                    left: "center"
                },
                series: [{
                        type: "pie",
                        radius: ["40%", "70%"],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: "center"
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 25,
                                fontWeight: "bold"
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.daySeatUSeVSFreeData
                    }]
            });
        },
        drawBarStuNormalVSBlack() {
            let barNormalVsBlack = echarts.init(this.$refs.barNormalVsBlack);
            barNormalVsBlack.setOption({
                tooltip: {
                    trigger: "item"
                },
                legend: {
                    top: "5%",
                    left: "center"
                },
                series: [{
                        type: "pie",
                        radius: ["40%", "70%"],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: "center"
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 25,
                                fontWeight: "bold"
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.dayStuNormalVSBlack
                    }]
            });
        },
    },
    mounted() {
        
        
    },
    components: { Icon }
}
</script>
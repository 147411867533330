import {getLoginUser} from "../api";

export const adminMenus = {
    path: '/home',
    name: 'home',
    component: require("../views/home.vue").default,
    children: [
        {
            path: '/welcome',
            name: '系统首页',
            icon: "ios-home",
            component: require("../views/pages/welcome.vue").default
        },
        {
            path: '/students',
            name: '人员管理',
            icon: "ios-home",
            component: require("../views/pages/students.vue").default
        },
        {
            path: '/clock',
            name: '考勤记录管理',
            icon: "ios-home",
            component: require("../views/pages/clock.vue").default
        },
        {
            path: '/check_in',
            name: '有效工时记录管理',
            icon: "ios-home",
            component: require("../views/pages/check_in.vue").default
        },
        {
            path: '/conference',
            name: '会议管理',
            icon: "ios-home",
            component: require("../views/pages/conference.vue").default
        },
    ]
}

export const managerMenus = {
    path: '/home',
    name: 'home',
    component: require("../views/home.vue").default,
    children: [
        {
            path: '/welcome',
            name: '系统首页',
            icon: "ios-home",
            component: require("../views/pages/welcome.vue").default
        },
       
    ]
}

//学生界面
export const studentMenus = {
    path: '/home',
    name: 'home',
    component: require("../views/home.vue").default,
    children: [
        {
            path: '/welcome',
            name: '系统首页',
            icon: "ios-home",
            component: require("../views/pages/welcome.vue").default
        }
    ]
}

export default function initMenu(router, store){

    let token = null;
	if(store.state.token){

		token = store.state.token;
	}else{

		token = sessionStorage.getItem("token");
		store.state.token = sessionStorage.getItem("token");
	}

	getLoginUser(token).then(resp =>{

		if(resp.data.type == 0){
			router.addRoute(adminMenus);
			store.commit("setMenus", adminMenus);
		}
	
		if(resp.data.type == 1){
			router.addRoute(managerMenus);
			store.commit("setMenus", managerMenus);
		}
		
		if(resp.data.type == 2){
			router.addRoute(studentMenus);
			store.commit("setMenus", studentMenus);
		}

        router.push('/welcome');
	});
}
import http from "../utils/http.js";


/** 系统接口 */
export function login(param){
	
	return http.post('/login/', param);
}
export function exit(token){
	
	return http.post('/exit/', {token: token});
}
export function getLoginUser(token){
	
	return http.get('/info/', {params: {token: token}});
}
export function updSessionInfo(param){
	
	return http.post('/info/', param);
}
export function updSessionPwd(param){
	
	return http.post('/pwd/', param);
}


/** 学生信息处理接口 */
export function getPageStudents(pageIndex, pageSize, stuName){

	return http.get('/users/page/student', 
        {params: {pageIndex: pageIndex, pageSize: pageSize, stuName: stuName}});
}
export function addStudents(params){
	
	return http.post('/student/add/', params);
}
export function updStudents(params){
	
	return http.post('/student/upd/', params);
}
export function delStudents(id){
	
	return http.post('/student/del/', {id: id});
}

/** 管理员信息处理接口 */
export function getPageManagers(pageIndex, pageSize, manName){

	return http.get('/users/page/manage', 
        {params: {pageIndex: pageIndex, pageSize: pageSize, manName: manName}});
}
export function addManagers(params){
	
	return http.post('/users/add/', params);
}
export function delManagers(id){
	
	return http.post('/users/del/', {id: id});
}


/** 打卡信息处理接口 */
export function getPageClock(pageIndex, pageSize, name){

	return http.get('/clock/page/clocks', 
        {params: {pageIndex: pageIndex, pageSize: pageSize, name: name}});
}
export function addClock(params){
	
	return http.post('/clock/add/', params);
}
export function updClock(params){
	
	return http.post('/clock/upd/', params);
}
export function delClock(id){
	
	return http.post('/clock/del/', {id: id});
}


/** 会议信息处理接口 */
export function getPageConference(pageIndex, pageSize, name){

	return http.get('/conference/page/conferences', 
        {params: {pageIndex: pageIndex, pageSize: pageSize, name: name}});
}
export function addConference(params){
	
	return http.post('/conference/add/', params);
}
//查询会议打卡信息
export function getClockList(conference_id){

	return http.get('/conference/clockConfList/', 
	{params: {conference_id: conference_id}});
}
export function getAbsenceClockList(conference_id){

	return http.get('/conference/AbsenceConfList/', 
	{params: {conference_id: conference_id}});
}

export function addAbsenceClock(conference_id,userID){

	return http.get('/checkin/addAbsence/', 
	{params: {conference_number: conference_id,user_id:userID}});
}


export function updConference(params){
	
	return http.post('/conference/upd/', params);
}
export function delConference(id){
	
	return http.post('/conference/del/', {id: id});
}


/** 黑名单记录 */
export function getPageBlackLogs(pageIndex, pageSize, stuId){

	return http.get('/blacklogs/page/', 
        {params: {pageIndex: pageIndex, pageSize: pageSize, stuId: stuId}});
}
export function outBlack(params){

	return http.post('/blacklogs/out/', params);
}



/** 会议考勤处理接口 */
export function getPageCheck_in(pageIndex, pageSize, name){

	return http.get('/checkin/page/check_ins', 
        {params: {pageIndex: pageIndex, pageSize: pageSize, name: name}});
}
export function addCheck_in(params){
	
	return http.post('/checkin/add/', params);
}
export function updCheck_in(params){
	
	return http.post('/checkin/upd/', params);
}
export function delCheck_in(id){
	
	return http.post('/checkin/del/', {id: id});
}

<template>
	<div class="fater-body-show">
        <Card>
            <template #title>
				信息查询
			</template>
			<div>
				<Form :model="qryForm" inline>
					<FormItem>
						<Input type="text" v-model="qryForm.Name" placeholder="学生姓名……"></Input>
					</FormItem>
					<FormItem>
						<Button type="primary" @click="getPageInfo(1, 10)">
							<Icon type="ios-search" />
						</Button>
					</FormItem>
				</Form>
			</div>
		</Card>

        <Card>
			<template #title>
				<Button @click="showAddWin()" type="primary">
					<Icon type="md-add" />
				</Button>
			</template>
			<div>
				<Table border :columns="columns" :loading="loading" :data="pageInfos">
					<template #action="{ row }">
						<Button style="margin-right: 5px;" 
                                size="small" type="info" icon="md-create" @click="showUpdWin(row)"></Button>
						<Button size="small" type="warning" icon="ios-trash" @click="delInfo(row.id)"></Button>
					</template>
				</Table>
				<Page style="margin-top: 15px;" v-if="pageTotal > 1" :current="pageIndex"
					@on-change="handleCurrentChange" :total="totalInfo" show-total/>
			</div>
		</Card>

        <Modal width="700" v-model="showAddFlag"
			title="信息编辑" ok-text="提交" cancel-text="取消" @on-ok="addInfo()">
            <Form :label-width="80" :model="Check_inForm">
                <!-- <Row :gutter="15">
                    <Col span="12">
                    <FormItem label="学生学号111">
                        <Input v-model="Check_inForm.check_in_id" placeholder="请输入学生学号..."></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="学生姓名(拼音)">
                            <Input v-model="ClockForm.name" placeholder="请输入学生姓名..."></Input>
                        </FormItem>
                    </Col>
                </Row>
                
                <Row :gutter="15">
                    <Col span="12">
                        <FormItem label="学生姓名">
                            <Input v-model="ClockForm.xingming" placeholder="请输入学生姓名..."></Input>
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="15">
                    <Col span="12">
                        <FormItem label="学生年龄">
                            <Input v-model="ClockForm.age" placeholder="请输入学生年龄..."></Input>
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="15">
                    <Col span="12">
                        <FormItem label="学生性别">
                            <RadioGroup v-model="ClockForm.sex">
                                <Radio label="男">男</Radio>
                                <Radio label="女">女</Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                </Row> -->
                
            </Form>
		</Modal> 
    </div>
</template>

<style></style>

<script>
import {
    getLoginUser,
    getPageCheck_in,
    addCheck_in,
    updCheck_in,
    delCheck_in,
} from '../../api/index.js';
export default{
		
    data(){
        return {
            pageBlack: {
                index: 1,
                size: 20,
                total: 0,
                infos: []
            },
            
            userInfo: {},
            colleges: [],
            grades: [],
            pageInfos: [],
            pageIndex: 1,
            pageSize: 10,
            pageTotal: 0,
            totalInfo: 0,
            loading: true,
            showAddFlag: false,
            showUpdFlag: false,
            qryForm: {
                stuName: "",
            },
            Check_inForm: {
                check_in_id: "",
                user_id: "",
                clock_time: "",
                stuName:"",
                clock_number: "",
                conference_number: "",
            },
            columns: []
        }
    },
    methods: {

        getPageInfo(pageIndex, pageSize) {
			
            getPageCheck_in(pageIndex, pageSize, 
                    this.qryForm.stuName, this.qryForm.collegeId, this.qryForm.gradeId).then(resp => {
                
                this.pageInfos = resp.data.data;
                this.pageIndex = resp.data.pageIndex;
                this.pageSize = resp.data.pageSize;
                this.pageTotal = resp.data.pageTotal;
                this.totalInfo = resp.data.count;
        
                this.loading = false;
            });
        },
        handleCurrentChange(pageIndex) {
        
            this.getPageInfo(pageIndex, this.pageSize);
        },
        showAddWin(){

            this.ClockForm = {
                check_in_id: "",
                user_id: "",
                clock_time: "",
                clock_number: "",
                working_time:"",
                conference_number: "",
            };
            this.showAddFlag = true;
        },	
        showUpdWin(row) {
			
            this.Check_inForm = row;
            this.showUpdFlag = true;
        },
        addInfo() {
			
            addCheck_in(this.Check_inForm).then(resp => {
                
                if(resp.code == 0){

                    this.$Notice.success({
                        duration: 3,
                        title: resp.msg
                    });
                    
                    this.getPageInfo(1, this.pageSize);
                    
                    this.showAddFlag = false;
                }else{

                    this.$Notice.warning({
                        duration: 3,
                        title: resp.msg
                    });
                    
                    this.showAddFlag = true;
                }
            });
        },
        updInfo() {
        
            updCheck_in(this.ClockForm).then(resp => {
        
                this.$Notice.success({
                    duration: 3,
                    title: resp.msg
                });
        
                this.getPageInfo(1, this.pageSize);
        
                this.showUpdFlag = false;
            });
        },
        delInfo(id){

            this.$Modal.confirm({
                title: '系统提示',
                content: '即将删除相关信息, 是否继续?',
                onOk: () => {
                    delCheck_in(id).then(resp =>{
                        
                        if(resp.code == 0){
                            this.$Notice.success({
                                duration: 3,
                                title: resp.msg
                            });
                            
                            this.getPageInfo(1, this.pageSize);
                        }else{
                            
                            this.$Notice.warning({
                                duration: 3,
                                title: resp.msg
                            });
                        }
                    });
                },
            });
        }	
    },
    mounted(){
        getLoginUser(this.$store.state.token).then(resp =>{
            this.userInfo = resp.data;
                this.columns = [
                    {title: '序号', type: 'index', width: 70, align: 'center'},
                    {title: '会议号', key: 'conference_number', align: 'center'},
                    {title: '姓名', key: 'stuName', align: 'center'},
                    {title: '用户id', key: 'user_id', align: 'center'},
                    {title: '打卡时间', key: 'clock_time', align: 'center'},
                ]
            this.getPageInfo(1, this.pageSize);
        });
    }
}
</script>
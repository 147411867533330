<template>
    <div class="fater-body-show">
        <Card>
            <template #title>
                信息查询
            </template>
            <div>
                <Form :model="qryForm" inline>
                    <FormItem>
                        <Input type="text" v-model="qryForm.conference_title" placeholder="会议名称……"></Input>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click="getPageInfo(1, 10)">
                            <Icon type="ios-search" />
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </Card>

        <Card>
            <template #title>
                <Button @click="showAddWin()" type="primary">
                    <Icon type="md-add" />
                </Button>
            </template>
            <div>
                <Table border :columns="columns" :loading="loading" :data="pageInfos">
                    <template #action="{ row }">
                        <Button style="margin-right: 5px;" size="small" type="info" icon="md-create"
                            @click="showUpdWin(row)"></Button>
                        <Button style="margin-right: 5px;" size="small" type="warning" icon="ios-trash"
                            @click="delInfo(row.conference_id)"></Button>
                        <!-- 展示打卡信息 -->
                        <Button style="margin-right: 5px;" size="small" type="primary"
                            @click="showClockWin(row.id)">已打卡</Button>
                        <Button style="margin-right: 5px;" size="small" type="primary" @click="showAbsenceClockWin(row.id)">补卡</Button>
                    </template>
                </Table>
                <Page style="margin-top: 15px;" v-if="pageTotal > 1" :current="pageIndex" @on-change="handleCurrentChange"
                    :total="totalInfo" show-total />
            </div>
        </Card>

        <Modal width="800" v-model="showClockFlag" title="信息编辑" ok-text="提交" cancel-text="取消" @on-ok="updInfo()">
            <Form :label-width="90" :model="roomForm">

            </Form>
        </Modal>


        <Modal width="800" v-model="showClockConfList" title="打卡详情" ok-text="确定" cancel-text="取消"> 
              
                <Table :columns="confListColumns" :data="confListData"></Table>
          
        </Modal>

        <Modal width="800" v-model="showAbsenceClockConfList" title="缺勤详情" ok-text="确定" cancel-text="取消">           
            
            <Table :columns="absenceConfListColumns" :data="absenceConfListData">
                <template #action="{ row, index }">
            <Button type="primary" size="small" style="margin-right: 5px" @click="AbsenceClock(index)">补卡</Button>
        </template>
            </Table>
       
            
        </Modal>

        <Modal width="700" v-model="showAddFlag" title="信息编辑" ok-text="提交" cancel-text="取消" @on-ok="addInfo()">
            <Form :label-width="80" :model="ClockForm">
                <Row :gutter="15">
                    <Col span="12">
                    <FormItem label="会议开始时间">
                        <Input v-model="ConferenceForm.conference_start_time" placeholder="请输入会议开始时间..."></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="会议名称">
                        <Input v-model="ConferenceForm.conference_title" placeholder="请输入会议名称..."></Input>
                    </FormItem>
                    </Col>
                </Row>

                <Row :gutter="15">
                    <Col span="12">
                    <FormItem label="会议地点">
                        <Input v-model="ConferenceForm.conference_location" placeholder="请输入会议地点..."></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row :gutter="15">
                    <Col span="12">
                    <FormItem label="会议备注">
                        <Input v-model="ConferenceForm.conference_notes" placeholder="请输入会议备注..."></Input>
                    </FormItem>
                    </Col>
                </Row>

            </Form>
        </Modal>
    </div>
</template>

<style></style>

<script>
import {
    getLoginUser,
    getPageConference,
    addConference,
    updConference,
    delConference,
    getClockList,
    getAbsenceClockList,
    addAbsenceClock
} from '../../api/index.js';
export default {

    data() {
        return {
            confListColumns: [
                    {
                        title: 'ID',
                        key: 'Sno'
                    },
                    {
                        title: '姓名',
                        key: 'stuName'
                    },
                    {
                        title: '打卡时间',
                        key: 'clock_time'
                    },
                    {
                        title: '状态',
                        key: 'State'
                    }
                ],
                confListData: [
                   
                ],
                absenceConfListColumns: [
                    {
                        title: 'ID',
                        key: 'Sno'
                    },
                    {
                        title: '姓名',
                        key: 'stuName'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 150,
                        align: 'center'
                    }
                ],
                absenceConfListData: [
                    
                ],
            pageBlack: {
                index: 1,
                size: 20,
                total: 0,
                infos: []
            },

            userInfo: {},
            conferences: [],
            grades: [],
            pageInfos: [],
            pageIndex: 1,
            pageSize: 10,
            pageTotal: 0,
            totalInfo: 0,
            loading: true,
            showAddFlag: false,
            showUpdFlag: false,
            showClockFlag: false,
            showClockConfList: false,
            showAbsenceClockConfList:false,
            qryForm: {
                Name: "",
            },
            ConferenceForm: {
                conference_id: "",
                conference_start_time: "",
                conference_title: "",
                conference_location: "",
                conference_notes: "",
            },
            columns: [],
            conferenceNumber:""
        }
    },
    methods: {

        AbsenceClock (index) {
                let conference_id = this.conferenceNumber;
                let userId = this.absenceConfListData[index].Sno;
                addAbsenceClock(conference_id,userId).then(resp =>{
                    // showAbsenceClockWin(conference_id)
                    console.log("回调成功")
                    getAbsenceClockList(conference_id).then(resp =>{
                
                this.absenceConfListData = resp.data.data;
                this.showAbsenceClockConfList = true;
                console.log("回调成功22")
            });
            });
            },
        getPageInfo(pageIndex, pageSize) {

            getPageConference(pageIndex, pageSize,
                this.qryForm.stuName, this.qryForm.collegeId, this.qryForm.gradeId).then(resp => {

                    this.pageInfos = resp.data.data;
                    this.pageIndex = resp.data.pageIndex;
                    this.pageSize = resp.data.pageSize;
                    this.pageTotal = resp.data.pageTotal;
                    this.totalInfo = resp.data.count;

                    this.loading = false;
                });
        },
        // getClockList(conference_id){
            
        // }
        showClockWin(conference_id) {
            
            this.showClockConfList = true;
            getClockList(conference_id).then(resp =>{
                this.confListData = resp.data.data;
                this.showClockConfList = true;
            });
        },
        showAbsenceClockWin(conference_id){
            this.conferenceNumber = conference_id;
            getAbsenceClockList(conference_id).then(resp =>{
                
                this.absenceConfListData = resp.data.data;
                this.showAbsenceClockConfList = true;
            });
        },
        handleCurrentChange(pageIndex) {

            this.getPageInfo(pageIndex, this.pageSize);
        },
        showAddWin() {

            this.ConferenceForm = {
                id: "",
                conference_start_time: "",
                conference_title: "",
                conference_location: "",
                conference_notes: "",
            };
            this.showAddFlag = true;
        },
        showUpdWin(row) {

            this.ConferenceForm = row;
            this.showUpdFlag = true;
        },
        addInfo() {

            addConference(this.ConferenceForm).then(resp => {

                if (resp.code == 0) {

                    this.$Notice.success({
                        duration: 3,
                        title: resp.msg
                    });

                    this.getPageInfo(1, this.pageSize);

                    this.showAddFlag = false;
                } else {

                    this.$Notice.warning({
                        duration: 3,
                        title: resp.msg
                    });

                    this.showAddFlag = true;
                }
            });
        },
        updInfo() {

            updConference(this.ConferenceForm).then(resp => {

                this.$Notice.success({
                    duration: 3,
                    title: resp.msg
                });

                this.getPageInfo(1, this.pageSize);

                this.showUpdFlag = false;
            });
        },
        delInfo(id) {

            this.$Modal.confirm({
                title: '系统提示',
                content: '即将删除相关信息, 是否继续?',
                onOk: () => {
                    delConference(id).then(resp => {

                        if (resp.code == 0) {
                            this.$Notice.success({
                                duration: 3,
                                title: resp.msg
                            });

                            this.getPageInfo(1, this.pageSize);
                        } else {

                            this.$Notice.warning({
                                duration: 3,
                                title: resp.msg
                            });
                        }
                    });
                },
            });
        }
    },
    mounted() {
        getLoginUser(this.$store.state.token).then(resp => {
            this.userInfo = resp.data;
            this.columns = [
                { title: '序号', type: 'index', width: 70, align: 'center' },
                { title: '会议id', key: 'id', align: 'center' },
                { title: '会议开始时间', key: 'conference_start_time', align: 'center' },
                { title: '会议标题', key: 'conference_title', align: 'center' },
                { title: '会议地点', key: 'conference_location', align: 'center' },
                { title: '会议备注', key: 'conference_notes', align: 'center' },
                { title: '操作', slot: 'action', align: 'center' }
            ]
            this.getPageInfo(1, this.pageSize);
        });
    }
}
</script>
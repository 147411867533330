<template>
	<div class="fater-body-show">
        <Card>
            <template #title>
				信息查询
			</template>
			<div>
				<Form :model="qryForm" inline>
					<FormItem>
						<Input type="text" v-model="qryForm.stuName" placeholder="学生姓名……"></Input>
					</FormItem>
					<FormItem>
						<Button type="primary" @click="getPageInfo(1, 10)">
							<Icon type="ios-search" />
						</Button>
					</FormItem>
				</Form>
			</div>
		</Card>

        <Card>
			<template #title>
				<Button @click="showAddWin()" type="primary">
					<Icon type="md-add" />
				</Button>
			</template>
			<div>
				<Table border :columns="columns" :loading="loading" :data="pageInfos">
					<template #action="{ row }">
						<Button style="margin-right: 5px;" 
                                size="small" type="info" icon="md-create" @click="showUpdWin(row)"></Button>
						<Button size="small" type="warning" icon="ios-trash" @click="delInfo(row.id)"></Button>
					</template>
				</Table>
				<Page style="margin-top: 15px;" v-if="pageTotal > 1" :current="pageIndex"
					@on-change="handleCurrentChange" :total="totalInfo" show-total/>
			</div>
		</Card>

        <Modal width="700" v-model="showAddFlag"
			title="信息编辑" ok-text="提交" cancel-text="取消" @on-ok="addInfo()">
            <Form :label-width="90" :model="studentForm">
                <Row :gutter="15">
                    <Col span="18">
                    <FormItem label="学生学号">
                        <Input v-model="studentForm.Sno" placeholder="请输入学生学号..."></Input>
                    </FormItem>
                    </Col>
                    
                </Row>
                <Row>
                    <Col span="18">
                        <FormItem label="学生姓名(拼音)">
                            <Input v-model="studentForm.name" placeholder="请输入学生姓名..."></Input>
                        </FormItem>
                    </Col>
                </Row>
                
                <Row :gutter="15">
                    <Col span="18">
                        <FormItem label="学生姓名">
                            <Input v-model="studentForm.xingming" placeholder="请输入学生姓名..."></Input>
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="15">
                    <Col span="12">
                        <FormItem label="学生年龄">
                            <Input v-model="studentForm.age" placeholder="请输入学生年龄..."></Input>
                        </FormItem>
                    </Col>
                </Row>
                <Row :gutter="15">
                    <Col span="12">
                        <FormItem label="学生性别">
                            <RadioGroup v-model="studentForm.sex">
                                <Radio label="男">男</Radio>
                                <Radio label="女">女</Radio>
                            </RadioGroup>
                        </FormItem>
                    </Col>
                </Row>
                
            </Form>
		</Modal> 
    </div>
</template>

<style></style>

<script>
import {
    getLoginUser,
    getPageStudents,
    addStudents,
    updStudents,
    delStudents,
} from '../../api/index.js';
export default{
		
    data(){
        return {
            pageBlack: {
                index: 1,
                size: 20,
                total: 0,
                infos: []
            },
            userInfo: {},
            pageInfos: [],
            pageIndex: 1,
            pageSize: 10,
            pageTotal: 0,
            totalInfo: 0,
            loading: true,
            showAddFlag: false,
            showUpdFlag: false,
            qryForm: {
                stuName: "",
            },
            studentForm: {
                id: "",
                name: "",
                sex: "",
                age: "",
                Sno: "",
                xingming:""
            },
            columns: []
        }
    },
    methods: {

        getPageInfo(pageIndex, pageSize) {
			
            getPageStudents(pageIndex, pageSize, 
                    this.qryForm.stuName).then(resp => {
                
                this.pageInfos = resp.data.data;
                this.pageIndex = resp.data.pageIndex;
                this.pageSize = resp.data.pageSize;
                this.pageTotal = resp.data.pageTotal;
                this.totalInfo = resp.data.count;
        
                this.loading = false;
            });
        },
        handleCurrentChange(pageIndex) {
        
            this.getPageInfo(pageIndex, this.pageSize);
        },
        showAddWin(){
            this.studentForm = {
                id: "",
                name: "",
                sex: "",
                age: "",
                Sno: "",
                xingming:""
            };
            this.showAddFlag = true;
        },	
        showUpdWin(row) {
			
            this.studentForm = row;
            this.showUpdFlag = true;
        },
        addInfo() {
			
            addStudents(this.studentForm).then(resp => {
                
                if(resp.code == 0){

                    this.$Notice.success({
                        duration: 3,
                        title: resp.msg
                    });
                    
                    this.getPageInfo(1, this.pageSize);
                    
                    this.showAddFlag = false;
                }else{

                    this.$Notice.warning({
                        duration: 3,
                        title: resp.msg
                    });
                    
                    this.showAddFlag = true;
                }
            });
        },
        updInfo() {
        
            updStudents(this.studentForm).then(resp => {
        
                this.$Notice.success({
                    duration: 3,
                    title: resp.msg
                });
        
                this.getPageInfo(1, this.pageSize);
        
                this.showUpdFlag = false;
            });
        },
        delInfo(id){

            this.$Modal.confirm({
                title: '系统提示',
                content: '即将删除相关信息, 是否继续?',
                onOk: () => {
                    delStudents(id).then(resp =>{
                        
                        if(resp.code == 0){
                            this.$Notice.success({
                                duration: 3,
                                title: resp.msg
                            });
                            
                            this.getPageInfo(1, this.pageSize);
                        }else{
                            
                            this.$Notice.warning({
                                duration: 3,
                                title: resp.msg
                            });
                        }
                    });
                },
            });
        }	
    },
    mounted(){
        getLoginUser(this.$store.state.token).then(resp =>{
            this.userInfo = resp.data;
                this.columns = [
                    {title: '序号', type: 'index', width: 70, align: 'center'},
                    {title: '学生学号', key: 'Sno', align: 'center'},
                    {title: '学生姓名', key: 'name', align: 'center'},
                    {title: '学生姓名(拼音)', key: 'xingming', align: 'center'},
                    {title: '学生年龄', key: 'Age', align: 'center'},
                    {title: '学生性别', key: 'Sex', align: 'center'},
                    {title: '操作', slot: 'action', align: 'center'}
                ]
            this.getPageInfo(1, this.pageSize);
        });
    }
}
</script>